/* Keyboard has same style and layout as Board. So I used that css */
.button {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.keyRow {

}

.grey {
  background-color: rgb(116, 115, 115);
  color: aliceblue;
}

.green {
  background-color: rgb(46, 201, 46);
  color: aliceblue;
}

.yellow {
  background-color: rgb(255, 191, 0);
  color: aliceblue;
}

#Enter {
  width: 7.5rem;
}

#Delete {
  width: 7.5rem;
}
